<template>
  <div class="search-popover" @click.stop>
    <div v-show="searchVisible" class="popover-content">
      <a-card
        hoverable
        :tab-list="tabListNoTitle"
        :active-tab-key="noTitleKey"
        size="small"
        class="search-card"
        @tabChange="key => onTabChange(key, 'noTitleKey')"
      >
        <!-- 员工 -->
        <div v-if="noTitleKey === '1'" class="user-search">
          <template v-if="loadComplate && userList.length > 0">
            <div v-for="(user,i) in userList" :key="i" class="user flex-align-center" @click="userClick(user,i)">
              <CbAvater
                :on-job-id="user.onJobId"
                :avater-name="user.staffName"
                is-person-info
                size="32"
                :default-name-length="2"
                :show-user-name="false"
              />
              <span v-if="user.staffName !== undefined &&user.staffName.length <4" class="user-name">{{ user.staffName }}</span>
              <a-tooltip v-else class="user-name">
                <template slot="title">
                  {{ user.staffName }}
                </template>
                <span v-if="user.staffName !== undefined">{{ user.staffName.slice(0,3) }}...</span>
              </a-tooltip>
              <span>{{ user.staffNum }}</span>
              <span class="user-job">{{ user.orgName }}<template v-if="user.postName">/{{ user.postName }}</template></span>
            </div>
          </template>
          <template v-else>
            <NoData />
          </template>
        </div>
        <!-- 部门 -->
        <div v-if="noTitleKey === '2'" class="apartment-search">
          <template v-if="loadComplate && apartmentList.length > 0">
            <div v-for="(apartment,i) in apartmentList" :key="i" class="apartment flex-align-center" @click="toOrgDetail(apartment)">
              <div class="apartment-icon flex-center">
                <a-icon class="icon" type="team" />
              </div>
              <span v-if="apartment.orgName !== undefined &&apartment.orgName.length < 4" class="apartment-name">{{ apartment.orgName }}</span>
              <a-tooltip v-else class="apartment-name">
                <template slot="title">
                  {{ apartment.orgName }}
                </template>
                <span v-if="apartment.orgName !== undefined">{{ apartment.orgName.slice(0,3) }}...</span>
              </a-tooltip>
              <span class="apartment-num">(在职人数{{ apartment.staffCount || 0 }}人)</span>
            </div>
          </template>
          <template v-else>
            <NoData />
          </template>
        </div>
        <!-- 功能 -->
        <div v-if="noTitleKey === '3'" class="popover-search">
          <template v-if="loadComplate && functionList.length > 0">
            <div
              v-for="(opration,i) in functionList"
              :key="i"
              class="search flex-align-center"
              @click="hrefToTarger(opration)"
            >
              <div class="icon-box flex-center">
                <a-icon class="icon flex-center" type="slack-square" />
              </div>
              <div class="search-content">
                <div class="search-user flex-just-between">
                  <span>{{ opration.menuName }}</span>
                </div>
                <span>{{ opration.subMenuName }}</span>
              </div>
            </div>
          </template>
          <template v-else>
            <NoData />
          </template>
        </div>
        <div class="popover-scroll" />
      </a-card>

    </div>
    <slot name="search" />
  </div>
</template>

<script>
import { getStaffVague } from '@/services/dropDownInput/index.js'
import { getOrgLikeOrgName } from '@/services/organization/framework.js'
export default {
  name: 'SearchPopover',
  components: {
    CbAvater: () => import('@/components/CbAvater/theAvater.vue'),
    NoData: () => import('@/components/CbNoData')
  },
  props: {
    searchValue: {
      type: String,
      default: ''
    }
  },
  data() {
    const hideMenu = ['userDetail']
    const routes = JSON.parse(localStorage.getItem('admin.permissions'))[0].children
    const functionData = []
    // 收集所有的二级菜单的数据集合
    routes.forEach(item => {
      if (item.children && item.children.length > 0) {
        item.children.forEach(key => {
          if (!hideMenu.includes(key.router)) {
            functionData.push({
              menuName: item.funcName,
              // menuIcon: item.meta.icon,
              subMenuName: key.funcName,
              path: `/${item.router}/${key.router}`
            })
          }
        })
      }
    })
    return {
      searchVisible: false,
      loadComplate: false,
      noTitleKey: '1',
      tabListNoTitle: [
        {
          tab: '员工',
          key: '1'
        },
        {
          tab: '部门',
          key: '2'
        },
        {
          tab: '功能',
          key: '3'
        }
      ],
      userList: [],
      apartmentList: [],
      functionData,
      functionList: []
    }
  },
  computed: {
    intPlaceholder() {
      const tabNameList = ['', '员工', '组织', '功能']
      return this.searchVisible ? `在${tabNameList[this.noTitleKey]}中搜索...` : '搜索员工/部门/功能'
    }
  },
  mounted() {
    document.addEventListener('click', () => {
      this.searchVisible = false
    })
  },
  methods: {
    userClick(user, i) {
      this.$router.push({
        path: '/staffIndex/userDetail',
        query: {
          staffId: user.staffId ? user.staffId : user.onJobId,
          onJobId: user.onJobId,
          path: encodeURIComponent(this.$route.fullPath)
        }
      })
      this.searchVisible = false
      this.userList = []
      this.$emit('update:searchValue', '')
    },
    async search() {
      if (!this.searchValue) {
        this.userList = []
        this.functionList = []
        this.apartmentList = []
        return
      }
      this.loadComplate = false
      const apiList = ['', 'searchUser', 'searchOrg', 'searchOpration']
      await this[apiList[this.noTitleKey]](this.searchValue)
      this.loadComplate = true
    },
    // 人员查询
    async searchUser(inputValue) {
      if (!inputValue) return
      const res = await getStaffVague({ inputValue })
      this.userList = res.data
    },
    // 组织查询
    async searchOrg(orgName) {
      const res = await getOrgLikeOrgName({ orgName })
      this.apartmentList = res.data
    },
    // 工能查询
    searchOpration(value) {
      this.functionList = JSON.parse(JSON.stringify(this.functionData)).filter(item => {
        return item.subMenuName.indexOf(value) > -1
      })
    },
    hrefToTarger(info) {
      this.$router.push(info.path)
      this.functionList = []
      this.searchVisible = false
      this.$emit('update:searchValue', '')
    },
    toOrgDetail(info) {
      this.searchVisible = false
      this.$router.push({
        path: '/organization/framework',
        query: {
          orgId: info.orgId
        }
      })
      this.apartmentList = []
      this.$emit('update:searchValue', '')
    },
    onTabChange(key, type) {
      this[type] = key
      this.userList = []
      this.apartmentList = []
      this.functionList = []
      this.$emit('update:searchValue', '')
    }
  }
}
</script>

<style lang="less" scoped >
.search-popover {
  position: relative;
  .popover-content {
    position: absolute;
    top: 40px;
    left: 0;
    width:404px;
    z-index: 99;
    .search-card{
      width: 100%;
      height:325px;
      box-shadow: 0px 4px 17px 1px rgba(73, 86, 128, 0.1);
      // 员工列表样式
      .user-search{
        color: @sc-grey-100;
        .user{
          margin-top:8px;
          padding:4px 6px;
        }
        .user:hover{
          background: @sc-greyBg-10;
          border-radius: 4px;
        }
        .font-size(14px);
        .user-name{
          margin-left:8px;
          display:inline-block;
          width:68px;
        }
        .user-job{
          margin-left:30px;
          color: @sc-grey-60;
        }
      }
      // 部门列表样式
      .apartment-search{
        color: @sc-grey-100;
       .apartment {
          margin-top:8px;
          padding:4px 6px;
          .apartment-icon{
            width:32px;
            height:32px;
            border-radius: 50%;
            background: @sc-greyBg-10;
            .icon{
              color:@sc-primary-100;
            }
          }
          .apartment-name{
            margin-left:8px;
            display:inline-block;
            width:68px;
          }
          .apartment-num{
            color: @sc-grey-60;
          }
       }
       .apartment:hover {
            background: @sc-greyBg-10;
            border-radius: 4px;
        }
      }
      // 功能列表样式
     .popover-search {
      .search {
        cursor: pointer;
        margin-bottom:9px;
        padding: 0px 6px;
        .icon-box {
          width:38px;
          height:38px;
          line-height:38px;
          background: @sc-greyBg-20;
          border-radius: 6px;
          .icon{
            width:18px;
            height:18px;
            color: @sc-primary-100;
          }
        }
        .search-user {
          span:first-child {
            color: @sc-grey-60;
            .font-size(14px)
          }
        }
        .search-content{
          margin:3px 0 0 8px;
        }
        .search-content > span {
          display: inline-block;
          width:326px;
          color: @sc-grey-100;
          .font-size(14px);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
       .search:hover{
        background:@sc-greyBg-10;
       }
    }

    }
  }
  .ant-popover-arrow {
    display: none;
  }
  .ant-popover-content {
    margin-top: 14px;
  }
  .ant-popover-inner-content {
    padding: 0;
  }
  // 滚动条
  ::-webkit-scrollbar {
      width: 4px;
    }
  ::-webkit-scrollbar-thumb {
      border-radius: 20px;
      background: @sc-greyBg-10;
    }
  ::-webkit-scrollbar-track {
      display:none;
  }
}
</style>
<style lang="less" scoped>
.ant-card {
   border-radius: 8px;
   border-color:#fff ;
  }
  .ant-card:hover {
    box-shadow: none;
  }
  .ant-card-head {
    padding: 0 13px !important;
  }
  /deep/.ant-tabs-tab {
    margin-right: 34px;
    padding: 10px 6px !important;
    .font-size(14px);
    color: @sc-grey-100;
  }
  /deep/.ant-tabs-tab:hover,/deep/.ant-tabs-tab-active {
    color: @sc-primary-100;
    font-weight: 400;
  }
  /deep/.ant-card-body {
    height: 282px;
    padding: 9px 8px;
    overflow: auto;

  }

</style>
