var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "search-popover",
      on: {
        click: function ($event) {
          $event.stopPropagation()
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.searchVisible,
              expression: "searchVisible",
            },
          ],
          staticClass: "popover-content",
        },
        [
          _c(
            "a-card",
            {
              staticClass: "search-card",
              attrs: {
                hoverable: "",
                "tab-list": _vm.tabListNoTitle,
                "active-tab-key": _vm.noTitleKey,
                size: "small",
              },
              on: {
                tabChange: function (key) {
                  return _vm.onTabChange(key, "noTitleKey")
                },
              },
            },
            [
              _vm.noTitleKey === "1"
                ? _c(
                    "div",
                    { staticClass: "user-search" },
                    [
                      _vm.loadComplate && _vm.userList.length > 0
                        ? _vm._l(_vm.userList, function (user, i) {
                            return _c(
                              "div",
                              {
                                key: i,
                                staticClass: "user flex-align-center",
                                on: {
                                  click: function ($event) {
                                    return _vm.userClick(user, i)
                                  },
                                },
                              },
                              [
                                _c("CbAvater", {
                                  attrs: {
                                    "on-job-id": user.onJobId,
                                    "avater-name": user.staffName,
                                    "is-person-info": "",
                                    size: "32",
                                    "default-name-length": 2,
                                    "show-user-name": false,
                                  },
                                }),
                                user.staffName !== undefined &&
                                user.staffName.length < 4
                                  ? _c("span", { staticClass: "user-name" }, [
                                      _vm._v(_vm._s(user.staffName)),
                                    ])
                                  : _c(
                                      "a-tooltip",
                                      { staticClass: "user-name" },
                                      [
                                        _c("template", { slot: "title" }, [
                                          _vm._v(
                                            " " + _vm._s(user.staffName) + " "
                                          ),
                                        ]),
                                        user.staffName !== undefined
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  user.staffName.slice(0, 3)
                                                ) + "..."
                                              ),
                                            ])
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                _c("span", [_vm._v(_vm._s(user.staffNum))]),
                                _c(
                                  "span",
                                  { staticClass: "user-job" },
                                  [
                                    _vm._v(_vm._s(user.orgName)),
                                    user.postName
                                      ? [_vm._v("/" + _vm._s(user.postName))]
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ],
                              1
                            )
                          })
                        : [_c("NoData")],
                    ],
                    2
                  )
                : _vm._e(),
              _vm.noTitleKey === "2"
                ? _c(
                    "div",
                    { staticClass: "apartment-search" },
                    [
                      _vm.loadComplate && _vm.apartmentList.length > 0
                        ? _vm._l(_vm.apartmentList, function (apartment, i) {
                            return _c(
                              "div",
                              {
                                key: i,
                                staticClass: "apartment flex-align-center",
                                on: {
                                  click: function ($event) {
                                    return _vm.toOrgDetail(apartment)
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "apartment-icon flex-center" },
                                  [
                                    _c("a-icon", {
                                      staticClass: "icon",
                                      attrs: { type: "team" },
                                    }),
                                  ],
                                  1
                                ),
                                apartment.orgName !== undefined &&
                                apartment.orgName.length < 4
                                  ? _c(
                                      "span",
                                      { staticClass: "apartment-name" },
                                      [_vm._v(_vm._s(apartment.orgName))]
                                    )
                                  : _c(
                                      "a-tooltip",
                                      { staticClass: "apartment-name" },
                                      [
                                        _c("template", { slot: "title" }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(apartment.orgName) +
                                              " "
                                          ),
                                        ]),
                                        apartment.orgName !== undefined
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  apartment.orgName.slice(0, 3)
                                                ) + "..."
                                              ),
                                            ])
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                _c("span", { staticClass: "apartment-num" }, [
                                  _vm._v(
                                    "(在职人数" +
                                      _vm._s(apartment.staffCount || 0) +
                                      "人)"
                                  ),
                                ]),
                              ],
                              1
                            )
                          })
                        : [_c("NoData")],
                    ],
                    2
                  )
                : _vm._e(),
              _vm.noTitleKey === "3"
                ? _c(
                    "div",
                    { staticClass: "popover-search" },
                    [
                      _vm.loadComplate && _vm.functionList.length > 0
                        ? _vm._l(_vm.functionList, function (opration, i) {
                            return _c(
                              "div",
                              {
                                key: i,
                                staticClass: "search flex-align-center",
                                on: {
                                  click: function ($event) {
                                    return _vm.hrefToTarger(opration)
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "icon-box flex-center" },
                                  [
                                    _c("a-icon", {
                                      staticClass: "icon flex-center",
                                      attrs: { type: "slack-square" },
                                    }),
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "search-content" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "search-user flex-just-between",
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(opration.menuName)),
                                      ]),
                                    ]
                                  ),
                                  _c("span", [
                                    _vm._v(_vm._s(opration.subMenuName)),
                                  ]),
                                ]),
                              ]
                            )
                          })
                        : [_c("NoData")],
                    ],
                    2
                  )
                : _vm._e(),
              _c("div", { staticClass: "popover-scroll" }),
            ]
          ),
        ],
        1
      ),
      _vm._t("search"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }